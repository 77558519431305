import { Grid, Box, Typography, Skeleton } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

export function LoadingVideoBox({ status = '' }: { status?: string }) {
    const isActive = status !== '';

    return (
        <Grid item xs={9} sm={3}>
            <Box sx={{
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: 3,
                position: 'relative',
                paddingTop: '175%',
                width: '100%',
                bgcolor: 'grey.300',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {isActive && <CircularProgress sx={{ position: 'absolute', top: '40%', transform: 'translate(-50%, -50%)' }} />}
                {isActive && (
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            top: '60%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            width: '80%',
                        }}
                    >
                        {status}
                    </Typography>
                )}
            </Box>
            <Skeleton variant="text" sx={{ fontSize: '1.25rem', mt: 1 }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
    );
}
