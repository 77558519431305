import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokIcon from './TikTokIcon';
import XIcon from './XIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import React from 'react';
import { VideoData } from './VideoData';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

interface VideoBoxProps {
    videoData: VideoData;
    onLoad?: () => void;
}

export function VideoBox({ videoData, onLoad }: VideoBoxProps) {
    const [videoKey, setVideoKey] = React.useState(0);
    const [addingSubtitles, setAddingSubtitles] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToYouTube, setPostingToYouTube] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToTikTok, setPostingToTikTok] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToX, setPostingToX] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const [postingToInstagram, setPostingToInstagram] = React.useState<'idle' | 'loading' | 'success'>('idle');
    const handleDownload = async () => {
        const video = videoData.videoUrl
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/download_video`, {
                params: { video: vid_path },
                responseType: 'blob', // Important for handling binary data
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${videoData.title}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading video:', error);
        }
    };

    // Update the handleAddSubs function
    const handleAddSubs = async () => {
        setAddingSubtitles('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/add_subs`, {
                params: { video: vid_path },
            });

            setAddingSubtitles('success');

            // Update the video URL with the new path received from the server
            videoData.videoUrl = response.data.updated_video_url;

            setVideoKey(prevKey => prevKey + 1);
        } catch (error) {
            console.error('Error adding subtitles:', error);
            setAddingSubtitles('idle');
        }
    };

    const handlePostToYouTube = async () => {
        setPostingToYouTube('loading');
        const isAuthenticated = await checkYouTubeAuth();
        if (!isAuthenticated) {
            // Redirect to YouTube auth
            const authResponse = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth`, { withCredentials: true });
            window.location.href = authResponse.data.auth_url;
            return;
        }
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_youtube`, {
                video: vid_path,
                title: videoData.title,
                description: videoData.description
            }, { withCredentials: true });

            if (response.status === 200) {
                setPostingToYouTube('success');
            }
        } catch (error) {
            console.error('Error posting to YouTube:', error);
            setPostingToYouTube('idle');
        }
    };
    const checkYouTubeAuth = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/youtube_auth_status`, { withCredentials: true });
            return response.data.authenticated;
        } catch (error) {
            console.error('Error checking YouTube auth status:', error);
            return false;
        }
    };


    const handlePostToTikTok = async () => {
        setPostingToTikTok('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_tiktok`, {
                video: vid_path,
                title: videoData.title,
                description: videoData.description
            });

            if (response.status === 200) {
                setPostingToTikTok('success');
            }
        } catch (error) {
            console.error('Error posting to TikTok:', error);
            setPostingToTikTok('idle');
        }
    };

    const handlePostToX = async () => {
        setPostingToX('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_x`, {
                video: vid_path,
                title: videoData.title,
                description: videoData.description
            });

            if (response.status === 200) {
                setPostingToX('success');
            }
        } catch (error) {
            console.error('Error posting to X:', error);
            setPostingToX('idle');
        }
    };
    const handlePostToInstagram = async () => {
        setPostingToInstagram('loading');
        const video = videoData.videoUrl;
        const vid_url = new URL(video);
        const vid_path = decodeURIComponent(vid_url.pathname).replace(/^\/video\//, '');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/post_to_instagram`, {
                video: vid_path,
                title: videoData.title,
                description: videoData.description
            });

            if (response.status === 200) {
                setPostingToInstagram('success');
            }
        } catch (error) {
            console.error('Error posting to Instagram:', error);
            setPostingToInstagram('idle');
        }
    };

    // Add error handling for video loading
    const handleVideoLoad = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        onLoad?.();
    };

    const handleVideoError = (event: React.SyntheticEvent<HTMLVideoElement>) => {
        console.error('Error loading video:', event);
        onLoad?.();
    };

    // Add ref to track component mounted state
    const isMounted = React.useRef(true);

    // Add cleanup on unmount
    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                    },
                }}
            >
                <Box sx={{
                    position: 'relative',
                    paddingTop: '177.78%',
                    bgcolor: 'grey.100',
                }}>
                    <video
                        key={videoKey}
                        controls
                        onLoadedData={handleVideoLoad}
                        onError={handleVideoError}
                        onAbort={(e) => {
                            // Ignore abort errors if component is unmounting
                            if (!isMounted.current) return;
                            console.warn('Video loading aborted:', e);
                            onLoad?.();
                        }}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        preload="metadata"
                    >
                        <source src={videoData.videoUrl} type="video/mp4" />
                    </video>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            mb: 1,
                            lineHeight: 1.2,
                            height: '2.4em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {videoData.title}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            mb: 2,
                            height: '3em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {videoData.description}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 0.5,
                        borderTop: 1,
                        borderColor: 'divider',
                        pt: 2,
                    }}>
                        <Tooltip
                            title={addingSubtitles === 'loading' ? "This may take a while..." : (addingSubtitles === 'success' || videoData.videoUrl.includes('subtitled')) ? "" : "Add Subtitles"}
                            arrow
                        >
                            <span>
                                <IconButton
                                    onClick={handleAddSubs}
                                    color="primary"
                                    sx={{ width: 36, height: 36 }}
                                    disabled={addingSubtitles !== 'idle' || videoData.videoUrl.includes('subtitled')}
                                >
                                    {(addingSubtitles === 'idle' && !videoData.videoUrl.includes('subtitled')) && <AddIcon />}
                                    {addingSubtitles === 'loading' &&
                                        <CircularProgress
                                            size={18}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-9px',
                                                marginLeft: '-9px'
                                            }}
                                        />}
                                    {(addingSubtitles === 'success' || videoData.videoUrl.includes('subtitled')) && <CheckIcon sx={{ color: 'green' }} />}
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Download Video" arrow>
                            <IconButton onClick={handleDownload} aria-label="download video" sx={{ width: 36, height: 36 }}>
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={postingToYouTube === 'loading' ? "Posting to YouTube..." : postingToYouTube === 'success' ? "Posted to YouTube" : "Post to YouTube"} arrow>
                            <span>
                                <IconButton
                                    onClick={handlePostToYouTube}
                                    color="primary"
                                    sx={{ width: 36, height: 36, color: "red" }}
                                    disabled={postingToYouTube !== 'idle'}
                                >
                                    {postingToYouTube === 'idle' && <YouTubeIcon />}
                                    {postingToYouTube === 'loading' && <CircularProgress
                                        size={18}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-9px',
                                            marginLeft: '-9px'
                                        }}
                                    />}

                                    {postingToYouTube === 'success' && <CheckIcon sx={{ color: 'green' }} />}
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={postingToTikTok === 'loading' ? "Posting..." : postingToTikTok === 'success' ? "Posted" : "Post to TikTok"} arrow>
                            <span>
                                <IconButton
                                    onClick={handlePostToTikTok}
                                    color="primary"
                                    sx={{ width: 36, height: 36, color: "" }}
                                    disabled={postingToTikTok !== 'idle'}
                                >
                                    {postingToTikTok === 'idle' && <TikTokIcon />}
                                    {postingToTikTok === 'loading' && <CircularProgress
                                        size={18}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-9px',
                                            marginLeft: '-9px'
                                        }}
                                    />}

                                    {postingToTikTok === 'success' && <CheckIcon sx={{ color: 'green' }} />}
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={postingToX === 'loading' ? "Posting..." : postingToX === 'success' ? "Posted" : "Post to X"} arrow>
                            <span>
                                <IconButton
                                    onClick={handlePostToX}
                                    color="primary"
                                    sx={{ width: 36, height: 36 }}
                                    disabled={postingToX !== 'idle'}
                                >
                                    {postingToX === 'idle' && <XIcon />}
                                    {postingToX === 'loading' && <CircularProgress
                                        size={18}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-9px',
                                            marginLeft: '-9px'
                                        }}
                                    />}

                                    {postingToX === 'success' && <CheckIcon sx={{ color: 'green' }} />}
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={postingToInstagram === 'loading' ? "Posting..." : postingToInstagram === 'success' ? "Posted" : "Post to Instagram"} arrow>
                            <span>
                                <IconButton
                                    onClick={handlePostToInstagram}
                                    color="primary"
                                    sx={{ width: 36, height: 36, color: "#E4405F" }}
                                    disabled={postingToInstagram !== 'idle'}
                                >
                                    {postingToInstagram === 'idle' && <InstagramIcon />}
                                    {postingToInstagram === 'loading' && <CircularProgress
                                        size={18}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-9px',
                                            marginLeft: '-9px'
                                        }}
                                    />}

                                    {postingToInstagram === 'success' && <CheckIcon sx={{ color: 'green' }} />}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}