import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Container, Box, Typography, Button } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios'; // Update axios import

import Hero from './components/Hero';
import AppBarToo from './components/AppBarToo';
import { useUser } from './contexts/UserContext';
import { VideoData } from './components/VideoData';
import { VideoBox } from './components/VideoBox';
import { LoadingVideoBox } from './components/LoadingVideoBox';
function ViralActionPage() {
    const location = useLocation();
    const { processing, error, errorMessage, sessionId } = location.state || {};
    const [videoDataArray, setVideoDataArray] = useState<VideoData[]>([]);
    const [isProcessing, setIsProcessing] = useState(processing || false);
    const { user } = useUser();
    const [statusDict, setStatusDict] = useState<Record<string, string>>({});
    const handleDownloadAll = async () => {
        if (videoDataArray.length === 0) return;

        // Extract directory path from the first video's URL
        const firstVideoUrl = videoDataArray[0].videoUrl;
        const url = new URL(firstVideoUrl);
        let directory = decodeURIComponent(url.pathname.substring(0, url.pathname.lastIndexOf('/')));
        directory = directory.replace(/^\/video\//, ''); // Remove leading "/video/"

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/download_videos`, {
                params: { directory },
                responseType: 'blob', // Important for handling binary data
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'videos.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading videos:', error);
        }
    };

    useEffect(() => {
        if (!user) return;

        let isFirstCall = true;

        const pollStatus = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_DOMAIN2}/api/getVideosStatus/${user.userId}`,
                    {
                        params: { session_id: sessionId },
                        withCredentials: true
                    }
                );

                const { status, currentStep, videos } = response.data;

                // Update status dictionary
                setStatusDict(currentStep);

                // Only set videos if either:
                // 1. Status is not completed, or
                // 2. Status is completed but it's not the first call
                if (videos && videos.length > 0 && (status !== 'completed' || !isFirstCall)) {
                    setVideoDataArray(videos);
                }

                // Stop polling if processing is completed
                if (status === 'completed') {
                    setIsProcessing(false);
                    clearInterval(pollInterval);
                } else {
                    setIsProcessing(true);
                }

                isFirstCall = false;
            } catch (error) {
                console.error('Error polling status:', error);
                setIsProcessing(false);
                clearInterval(pollInterval);
            }
        };

        // Initial check
        pollStatus();

        // Set up polling
        const pollInterval = setInterval(pollStatus, 5000);
        return () => clearInterval(pollInterval);
    }, [user, sessionId]);

    if (error) {
        return (
            <>
                <AppBarToo />
                <Hero error={true} message={errorMessage || "There was an error handling your request. Please try again later"} landing={false} />
            </>
        );
    }

    // If not processing and no videos, show the input form
    if (!isProcessing && videoDataArray.length === 0) {
        return (
            <>
                <AppBarToo />
                <Hero landing={false} />
            </>
        );
    }

    return (
        <Container maxWidth="lg">
            <AppBarToo />
            <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0, display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    sx={{
                        fontSize: "2.5vw",
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap', // Forces single line
                    }}
                >
                    {videoDataArray[0] ? videoDataArray[0].id : (
                        <Box component="span">
                            Generating Clips
                            <Box
                                component="span"
                                sx={{
                                    '@keyframes ellipsis': {
                                        '0%': { content: '"."' },
                                        '33%': { content: '".."' },
                                        '66%': { content: '"..."' },
                                    },
                                    '&::after': {
                                        content: '"."',
                                        animation: 'ellipsis 5s infinite',
                                        display: 'inline-block',
                                        width: '1em',
                                    }
                                }}
                            />
                        </Box>
                    )}
                </Typography>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {videoDataArray.map((videoData: VideoData) => (
                        <VideoBox key={videoData.id} videoData={videoData} />
                    ))}
                    {isProcessing && Object.entries(statusDict).map(([key, status]) => (
                        status !== 'done' && !status.toLowerCase().startsWith('error') &&
                        <LoadingVideoBox key={key} status={status} />
                    ))}
                </Grid>
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleDownloadAll}
                        startIcon={<DownloadIcon />}
                        disabled={isProcessing}
                    >
                        Download All
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default ViralActionPage;