import React from 'react';
import { Box, Typography } from '@mui/material';
import MovieIcon from '@mui/icons-material/Movie';

export const ClipsHeader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 6,
                p: 3,
                borderRadius: '24px',
                background: 'linear-gradient(135deg, #f8fdfc, #edf8f6)',
                boxShadow: '0 8px 32px rgba(26, 182, 157, 0.15)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 12px 40px rgba(26, 182, 157, 0.2)',
                }
            }}
        >
            <MovieIcon
                sx={{
                    fontSize: 40,
                    mr: 3,
                    color: '#1AB69D',
                    filter: 'drop-shadow(0 2px 4px rgba(26, 182, 157, 0.2))'
                }}
            />
            <Typography
                variant="h3"
                component="h1"
                sx={{
                    fontWeight: 600,
                    color: '#2D3748',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: -4,
                        left: 0,
                        width: '100%',
                        height: 3,
                        background: 'linear-gradient(90deg, #1AB69D, transparent)',
                        borderRadius: '2px'
                    }
                }}
            >
                My Clips
            </Typography>
        </Box>
    );
};