import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, CircularProgress, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AppBarToo from './components/AppBarToo';
import { VideoBox } from './components/VideoBox';
import { VideoData } from './components/VideoData';
import { ClipsHeader } from './components/ClipsHeader';

export default function ViralVideoPage() {
    const [videoDataArray, setVideoDataArray] = useState<VideoData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/list_videos`, {}, { withCredentials: true });
                setVideoDataArray(response.data);
            } catch (err) {
                setError(true);
                console.error('Error fetching video data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchVideoData();
    }, []);

    // Group videos by id
    const groupedVideos = videoDataArray.reduce((acc, video) => {
        if (!acc[video.id]) {
            acc[video.id] = [];
        }
        acc[video.id].push(video);
        return acc;
    }, {} as Record<string, VideoData[]>);

    const [expandedId, setExpandedId] = useState<string | null>(null);

    const handleAccordionChange = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedId(isExpanded ? id : null);
    };

    if (loading) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error || videoDataArray.length === 0) {
        return (
            <Container maxWidth="xl">
                <AppBarToo />
                <Box
                    sx={{
                        pt: 8,
                        pb: 6,
                        p: 4,
                        margin: 'auto',
                        maxWidth: '800px',
                    }}
                >
                    <ClipsHeader />

                    <Box
                        sx={{
                            my: 4,
                            borderBottom: 2,
                            borderColor: 'rgba(26, 182, 157, 0.1)',
                            width: '100%',
                            maxWidth: '200px',
                            mx: 'auto'
                        }}
                    />

                    <Typography
                        variant="h4"
                        component="h4"
                        align='center'
                        sx={{
                            mb: 4,
                            color: 'text.secondary',
                            fontWeight: 500
                        }}
                    >
                        {error ? "An error occurred while fetching your clips." : "Looks empty here."}
                    </Typography>

                    <Button
                        variant="outlined" // Changed to contained for better emphasis
                        size="large"
                        sx={{
                            mt: 3,
                            py: 2, // Taller button
                            px: 6, // Wider button
                            backgroundColor: 'hsl(170, 75%, 35%)',
                            '&:hover': {
                                backgroundColor: 'hsl(170, 75%, 30%)',
                                transform: 'translateY(-1px)',
                                boxShadow: '0 8px 20px rgba(26, 182, 157, 0.3)',
                            },
                            transition: 'all 0.3s ease',
                            borderRadius: '12px',
                            fontWeight: 600,
                            color: "white",
                            fontSize: '1.1rem',
                            width: '100%',
                            maxWidth: '400px',
                            margin: '0 auto',
                            display: 'block'
                        }}
                        onClick={() => error ? navigate("/viral-video") : navigate('/viral-action')}
                    >
                        {error ? "Try again" : "Generate your first clip"}
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <AppBarToo />
            <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0 }}>
                <ClipsHeader />
                {Object.entries(groupedVideos).map(([id, videos]) => (
                    <Accordion
                        key={id}
                        expanded={expandedId === id}
                        onChange={handleAccordionChange(id)}
                        sx={{
                            width: '100%',
                            maxWidth: '1200px',
                            margin: '0 auto 16px',
                            backgroundColor: 'hsl(170, 75%, 98.5%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: '0 auto 16px',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${id}-content`}
                            id={`panel-${id}-header`}
                            sx={{
                                borderRadius: '8px',
                                '&.Mui-expanded': {
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                },
                            }}
                        >
                            <Typography variant="h6">{id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {expandedId === id && (
                                <Box sx={{
                                    display: 'flex',
                                    overflowX: 'auto',
                                    gap: 2,
                                    width: '100%',
                                    pb: 2,
                                    '&::-webkit-scrollbar': {
                                        height: 8,
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                        borderRadius: 4,
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#888',
                                        borderRadius: 4,
                                    },
                                }}>
                                    {videos.map((video, index) => (
                                        <Box key={`${id}-${index}`} sx={{ width: '250' }}>
                                            <VideoBox videoData={video} />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
}