import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider, useUser } from './contexts/UserContext';
import MarketingPage from './MarketingPage';
import ViralActionPage from './ViralActionPage';
import getMPTheme from './theme/getMPTheme';
import ViralVideoPage from './ViralVideoPage';
import AboutUs from './pages/AboutUs';
import Box from '@mui/material/Box';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import SubscriptionManagement from './components/SubscriptionManagement';
import { LoginProvider } from './contexts/LoginContext';
import CircularProgress from '@mui/material/CircularProgress';

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Reload the page on error
    window.location.reload();
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error before reload
    console.error('Error caught:', error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useUser();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MarketingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/viral-action" element={
        <ProtectedRoute>
          <ViralActionPage />
        </ProtectedRoute>
      } />
      <Route path="/viral-video" element={
        <ProtectedRoute>
          <ViralVideoPage />
        </ProtectedRoute>
      } />
      <Route path="/about" element={
        <ProtectedRoute>
          <AboutUs />
        </ProtectedRoute>
      } />
      <Route path="/subscription-management" element={
        <ProtectedRoute>
          <SubscriptionManagement />
        </ProtectedRoute>
      } />
    </Routes>
  );
}

function App() {
  const MPTheme = createTheme(getMPTheme("light"));
  const GOOGLE_CLIENT_ID = '394403859303-e8dqjpnjf2fhjmitb8tc9c2v4vdo2rv3.apps.googleusercontent.com';

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <UserProvider>
          <LoginProvider>
            <ThemeProvider theme={MPTheme}>
              <CssBaseline />
              <Box
                sx={(theme) => ({
                  width: '100%',
                  minHeight: '100vh', // Ensure it covers the full viewport height
                  backgroundRepeat: 'no-repeat',
                  backgroundAttachment: 'fixed', // This keeps the background fixed while scrolling
                  backgroundSize: 'cover',
                  backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(170, 75%, 85%), transparent)',
                  ...theme.applyStyles('dark', {
                    backgroundImage:
                      'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(170, 75%, 85%), transparent)',
                  }),
                })}
              >
                <Router>
                  <AppRoutes />
                </Router>
              </Box>
            </ThemeProvider>
          </LoginProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;